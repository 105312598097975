import { useTranslation } from 'react-i18next'
import cx from 'clsx'
import { useParams } from 'react-router-dom'
import { validate } from 'uuid'
import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

import Datepicker from '@/components/Datepicker'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { Button, Drawer, Textarea, TextInput } from 'flowbite-react'

import { useIsIos, useIsMobileDevice } from '@/utils/hooks/useIsMobileDevice'
import {
  useCreateSprint,
  useDeleteSprint,
  useGetSprint,
  useGetSprints,
  useMoveTickets,
  useUpdateSprint,
} from '@/projects/queries/sprints'
import { useToggleOpen } from '@/utils/hooks/useToggle'

import { SprintStatus } from '@/projects/models/ISprint'
import { useGetProject } from '@/projects/queries'

export interface SprintSettingsProps {
  isOpen: boolean
  id: string
  handleClose: () => void
}

type Inputs = {
  name: string
  goal: string
  start_date: Date | null
  end_date: Date | null
}

export const SprintSettings: React.FC<SprintSettingsProps> = ({ isOpen, id, handleClose }) => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()

  const [isFocused, setIsFocused] = useState(false)
  const { isOpen: isDeleteOpen, toggleOpen: toggleDeleteOpen } = useToggleOpen()

  const { t } = useTranslation('projects', { keyPrefix: 'sprint' })

  const isValidUuid = validate(id)
  const { data: sprints } = useGetSprints(projectId, teamspaceId)
  const { data: sprint } = useGetSprint(projectId, teamspaceId, isValidUuid && isOpen ? id : undefined)
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: createSprint, isPending: isCreating } = useCreateSprint(projectId, teamspaceId)
  const { mutateAsync: uppdateSprint, isPending: isUpdating } = useUpdateSprint(
    projectId,
    teamspaceId,
    isValidUuid ? id : undefined,
  )
  const { mutateAsync: deleteSprint } = useDeleteSprint(projectId, teamspaceId, isValidUuid ? id : undefined)
  const { mutateAsync: moveTickets, isPending: isTicketsMoving } = useMoveTickets(projectId, teamspaceId)

  const isIos = useIsIos()
  const isMobile = useIsMobileDevice()

  const { register, setValue, handleSubmit, watch } = useForm<Inputs>({
    defaultValues: {
      name: '',
      goal: '',
      start_date: new Date(),
      end_date: dayjs().add(2, 'week').toDate(),
    },
  })

  register('start_date')
  register('end_date')

  useEffect(() => {
    if (sprint) {
      setValue('name', sprint.name)
      setValue('goal', sprint.goal)
      setValue('start_date', new Date(sprint.start_date))
      setValue('end_date', new Date(sprint.end_date))
    } else {
      const latestSprint = sprints
        ?.sort((a, b) => dayjs(b.end_date).unix() - dayjs(a.end_date).unix())
        .find((sprint) => !!sprint.end_date)

      setValue('name', `${t('sprintLabel')} ${sprints?.length + 1}`)

      if (latestSprint) {
        setValue('start_date', dayjs(new Date(latestSprint?.end_date)).add(1, 'day').toDate())
        setValue('end_date', dayjs(new Date(latestSprint?.end_date)).add(2, 'week').add(1, 'day').toDate())
      }
    }
  }, [sprint, sprints, t])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const action = isValidUuid ? uppdateSprint : createSprint
      await action({
        name: data.name,
        start_date: dayjs(data.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(data.end_date).format('YYYY-MM-DD'),
        status: SprintStatus.CREATED,
        goal: data.goal,
      })
      handleClose()
    } catch (error) {
      const axiosErros = error as AxiosError<{ detail: string }>
      if (axiosErros?.response?.data?.detail) {
        toast.error(axiosErros.response.data.detail)
      }
    }
  }

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    if (['sprint-name', 'sprint-goal'].includes(e.relatedTarget?.id || '')) return
    setIsFocused(false)
  }, [])

  const nameField = register('name', {
    onBlur: handleBlur,
  })
  const goalField = register('goal', {
    onBlur: handleBlur,
  })

  const onFieldFocus = () => {
    setIsFocused(true)
  }

  const handleDeleteSprint = async () => {
    try {
      await moveTickets({
        source_sprint_uuid: id,
        to_backlog: true,
        status_uuids: project.settings.statuses.map((status) => status.uuid),
      })
      await deleteSprint()
      handleClose()
    } catch (error) {
      const axiosErros = error as AxiosError<{ detail: string }>
      if (axiosErros?.response?.data?.detail) {
        toast.error(axiosErros.response.data.detail)
      }
    }
  }

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      className={cx('rounded-t-md', {
        'pb-10': isIos,
        'h-[700px]': isMobile && isFocused,
      })}
      position="bottom"
    >
      <Drawer.Header title={t('settings.title')} titleIcon={() => null} />
      {isOpen && (
        <Drawer.Items>
          <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <p className="text-sm">{t('settings.nameLabel')}</p>
              <TextInput {...nameField} sizing="xs" onFocus={onFieldFocus} id="sprint-name" />
            </div>
            <div className="flex gap-2">
              <Datepicker
                onChange={(value) => setValue('start_date', value)}
                value={watch('start_date')}
                isClearable={false}
                className="grow"
                buttonClassName="justify-start"
                label={t('settings.startDateLabel')}
              />
              <Datepicker
                onChange={(value) => setValue('end_date', value)}
                value={watch('end_date')}
                isClearable={false}
                className="grow"
                buttonClassName="justify-start"
                label={t('settings.endDateLabel')}
              />
            </div>
            <Textarea
              placeholder="Goal"
              {...goalField}
              className="resize-none"
              onFocus={onFieldFocus}
              id="sprint-goal"
              rows={5}
            />
            <div className="flex justify-between">
              {isValidUuid && (
                <Button size="xs" color="red" onClick={toggleDeleteOpen}>
                  {t('settings.deleteLabel')}
                </Button>
              )}
              <ConfirmationDialog
                onClose={toggleDeleteOpen}
                isOpen={isDeleteOpen}
                isLoading={isTicketsMoving}
                onConfirm={handleDeleteSprint}
                confirmationTitle={t('settings.deleteConfirmationTitle')}
                confirmationText={t('settings.deleteConfirmationText')}
              />
              <div className="flex grow justify-end">
                <Button size="xs" color="green" isProcessing={isCreating || isUpdating} type="submit">
                  {t('settings.saveLabel')}
                </Button>
              </div>
            </div>
          </form>
        </Drawer.Items>
      )}
    </Drawer>
  )
}

export default SprintSettings
